@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Bold.ttf");
  font-weight: 700;
}
